var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"id":"form-add-user"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"general-form",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"general-form__col",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Nama Pengguna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Nama Pengguna")]),_c('v-text-field',{attrs:{"placeholder":"Nama Pengguna","error-messages":errors,"clearable":"","type":"text","outlined":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("E-mail")]),_c('v-text-field',{attrs:{"placeholder":"E-mail","error-messages":errors,"clearable":"","type":"text","outlined":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Nomor HP","rules":"required|phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Nomor HP")]),_c('v-text-field',{attrs:{"placeholder":"Nomor HP","prefix":"+62","error-messages":errors,"clearable":"","outlined":"","dense":"","type":"number"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,true)})],1),_c('v-col',{staticClass:"general-form__col",attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('div',[_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Peran")]),_c('v-select',{attrs:{"items":_vm.roles,"dense":"","outlined":""},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1),(_vm.isEdit)?_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Bila password diisi, password sebelumnya akan di ganti")]):_vm._e(),_c('validation-provider',{attrs:{"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"title-14 mb-2"},[_vm._v("Pasword")]),_c('v-text-field',{attrs:{"label":"Password","placeholder":"Password","error-messages":errors,"clearable":"","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"outlined":"","dense":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"primary","min-width":160,"type":"submit","form":"form-add-user","large":"","disabled":_vm.isDisabled || invalid}},[_vm._v(" SIMPAN ")])],1)],1)]}}])}),_c('dialog-info',{ref:"dialogInfoSuccess",attrs:{"info":_vm.infoSuccess,"action":_vm.handleBack}}),_c('dialog-confirm',{ref:"dialogConfirmSubmitForm",attrs:{"info":_vm.info,"action":_vm.checkAddUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }