<template>
  <div>
    <validation-observer v-slot="{ invalid }">
      <v-form id="form-add-user" @submit.prevent="submitForm">
        <v-row no-gutters class="general-form">
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Nama Pengguna"
              rules="required"
            >
              <p class="title-14 mb-2">Nama Pengguna</p>
              <v-text-field
                placeholder="Nama Pengguna"
                v-model="name"
                :error-messages="errors"
                clearable
                type="text"
                outlined
                dense
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="E-mail"
              rules="required|email"
            >
              <p class="title-14 mb-2">E-mail</p>
              <v-text-field
                placeholder="E-mail"
                v-model="email"
                :error-messages="errors"
                clearable
                type="text"
                outlined
                dense
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Nomor HP"
              rules="required|phoneNumber"
            >
              <p class="title-14 mb-2">Nomor HP</p>
              <v-text-field
                placeholder="Nomor HP"
                prefix="+62"
                v-model="phoneNumber"
                :error-messages="errors"
                clearable
                outlined
                dense
                type="number"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="6"
            class="general-form__col"
          >
            <div>
              <p class="title-14 mb-2">Peran</p>
              <v-select
                v-model="role"
                :items="roles"
                dense
                outlined
              ></v-select>
            </div>
            <p v-if="isEdit" class="title-14 mb-2">Bila password diisi, password sebelumnya akan di ganti</p>
            <validation-provider
              v-slot="{ errors }"
              name="Password"
            >
              <p class="title-14 mb-2">Pasword</p>
              <v-text-field
                label="Password"
                placeholder="Password"
                v-model="password"
                :error-messages="errors"
                clearable
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                outlined
                dense
                @click:append="show = !show"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <div class="d-flex justify-end">
          <v-btn 
            color="primary"
            :min-width="160"
            type="submit"
            form="form-add-user"
            large
            :disabled="isDisabled || invalid"
          >
            SIMPAN
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="checkAddUser"/>
  </div>
</template>

<script>
  // Libs
  import { get } from 'dot-prop'
  import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
  import CryptoJS from 'crypto-js'

  // Utils
  import loadscreen from '@/utils/common/loadScreen'
  import request from '@/utils/request'

  import {
    API_DASH_ADD_USER,
    API_DASH_UPDATE_USER
  } from '@/constants/apis'
  import {
    USER_SETTING
  } from '@/constants/pages'

  import DialogConfirm from '@/components/common/DialogConfirm'
  import DialogInfo from '@/components/common/DialogInfo'
  

  export default {
    name: 'addUserForm',
    components: {
      ValidationObserver,
      ValidationProvider,
      DialogConfirm,
      DialogInfo
    },
    data () {
      return {
        id: '',
        name: '',
        role: 'admin',
        email: '',
        phoneNumber: '',
        password: '',
        show: false,
        info: {
          desc: 'Anda yakin data yang anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.submit.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    computed: {
      isDisabled () {
        if (this.isEdit) {
          return this.isError || !this.name || !this.email || !this.phoneNumber
        }
        return this.isError || !this.name || !this.email || !this.phoneNumber || !this.password
      },
      roles () {
        return [
          'admin',
          'dosen',
          'staff admin'
        ]
      },
      isEdit () {
        return get(this.$route.query, 'source', 'add') === 'edit'
      }
    },
    created () {
      if (this.isEdit) {
        const userData = get(this.$store.state, 'dashboard.userData', {})
        this.id = userData.id
        this.name = userData.name,
        this.role = userData.role
        this.email = userData.email
        this.phoneNumber = userData.phone
      }
    },
    mounted () {
      extend('required', required)
      extend('email', email)
      extend('phoneNumber', {
        validate: (value) => {
          const MOBILEREG = /^(^8)(\d{3,4}-?){2}\d{2,3}$/g
          if (MOBILEREG.test(value)) {
            return true
          }
          return 'WA Phone Number is not valid'
        }
      })
    },
    methods: {
      submitForm () {
        this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
      },
      async checkAddUser () {
        this.$emit('handleAlert', false, '')
        loadscreen.show()
        let apiUrl = API_DASH_ADD_USER
        const params = {
          name: this.name,
          role: this.role.replace(' ','_'),
          email: this.email,
          phone: this.phoneNumber,
        }
        if (this.isEdit) {
          apiUrl = API_DASH_UPDATE_USER
          params.id = this.id
        }
        this.password ? params.password = CryptoJS.SHA256(this.password).toString() : ''
        const res = await request(apiUrl, params)
        if (res.success) {
          this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
        } else {
          const message = get(res, 'resultInfo.resultMsg', '')
          this.$emit('handleAlert', true, message)
        }
        loadscreen.hide()
      },
      handleBack () {
        this.$emit('setIsDone')
        this.$router.replace({
          name: USER_SETTING
        }).catch(() => {})
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
</style>
