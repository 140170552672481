<template>
  <div class="add-user">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2 class="mb-4">{{ title | text }}</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <add-user-form @handleAlert="handleAlert" @setIsDone="setIsDone"/>
        </v-card>
      </div>
    </v-container>
    <dialog-confirm ref="dialogConfirmSheetForm" :info="info" :action="handleMove"/>
  </div>
</template>

<script>
import addUserForm from '@/apps/dashboard/components/forms/addUser'
import {
  USER_SETTING
} from '@/constants/pages'
import { get } from 'dot-prop'
import DialogConfirm from '@/components/common/DialogConfirm'
  export default {
    name: 'AddUser',
    components: {
      addUserForm,
      DialogConfirm,
    },
    data () {
      return {
        isShowAlert: false,
        alertMsg: '',
        isDone: false,
        path: '',
        info: {
          desc: 'Anda yakin? Data yang anda masukan akan hilang'
        },
      }
    },
    computed: {
      title () {
        return this.$route.query.source === 'add' ? 'dash.add.user.title' : 'dash.edit.user.title'
      }
    },
    created () {
      window.addEventListener('beforeunload', this.handleBeforeUnload)
    },
    beforeRouteEnter (to, from, next) {
      if (from.name === null) {
        next(vm => {
          vm.isDone = true
          vm.$router.replace({
            name: USER_SETTING,
          })
        })
      } else {
        next()
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.isDone) {
        next()
      } else {
        this.path = to.name
        this.$refs.dialogConfirmSheetForm && this.$refs.dialogConfirmSheetForm.show()
      }
    },
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.handleBeforeUnload)
    },
    methods: {
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      },
      handleMove () {
        this.isDone = true
        this.path = this.path ? this.path : USER_SETTING
        this.$router.replace({
          name: this.path,
        }).catch(() => {})
      },
      handleBeforeUnload (event) {
        event.preventDefault()
        event.returnValue = ''
      },
      setIsDone () {
        this.isDone = true
      }
    }
  }
</script>

<style lang="scss" scoped>
.add-user {

}
</style>